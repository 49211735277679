import "owl.carousel";
import "lazysizes";
import Slugify from "slugify";
import Autocomplete from "./components/autocomplete";
import alertNews from "./components/alert-news";
import header from "./components/header";
import "./monkey-patch";
import common from "./components/common";

const Main = {
  mapInstance: undefined,

  /**
   * Carrosel
   *
   */
  initCarousel: () => {
    $("[data-owl-carousel]").each(function () {
      const items = $(this).data("items");
      const itemsMobile = $(this).data("items-mobile");

      let owl = {
        dots: true,
        lazyLoad: true,
        loop: true,
        touchDrag: true,
        responsive: {
          0: {
            items: itemsMobile || 1,
          },
          1024: {
            items,
          },
        },
      };

      if ($(this).data("autoplay")) {
        owl.autoplay = true;
        owl.autoplayTimeout = 5000;
        owl.rewind = true;
        owl.autoplayHoverPause = true;
      }

      $(this).owlCarousel(owl);
    });
  },
  /**
   * Controla abertura dos subitens no menu mobile
   *
   */
  controlMobileMenu: () => {
    $(document).on("click", ".actControlMobileMenu", function () {
      const menu = $(this).data("menu");
      const after = $(this).data("after");

      $("#listSubMenusMobile.clone").remove();

      let $area = $("#listSubMenusMobile").clone().addClass("clone");

      $(`[data-sub-menu=${menu}]`).each(function () {
        let item = $(this).clone();

        $area.append(item);
      });

      $(".item-mobile").addClass("-opacity");
      $(this).removeClass("-opacity");

      $('[data-index-menu="' + after + '"]').after($area);
    });
  },
  /**
   * Botão voltar ao header
   *
   */
  scrollUp: () => {
    $(document).on("click", "[data-arrow=goToUp]", function () {
      $("html, body").animate({ scrollTop: 0 }, 500);
    });
  },
  /**
   * Modal de video de previsão e busca
   *
   */
  controlModal: () => {
    $(document).on("click", ".actControlModal", function () {
      const modal = $(this).data("modal");
      const modalElement = document.getElementById(`${modal}`);
      const hasInput = modalElement.querySelectorAll("input")[0];
      let activatedVideo = $("[data-video-modal]").hasClass("-activated");
      let renderedSearch = $("[data-search-modal]").hasClass("-rendered");

      if (modal === "forecastVideo" && !activatedVideo) {
        $("[data-video-modal]").addClass("-activated");
        const videoId = $("#videoPlayerModal").data("video-id");

        /* build video iframe tag */
        var videoScript = document.createElement("iframe");
        videoScript.width = "100%";
        videoScript.height = "100%";
        videoScript.setAttribute("allowFullScreen", "");
        videoScript.frameborder = "0";
        videoScript.src = `https://www.youtube.com/embed/${videoId}`;

        let videoPlayer = document.getElementById("videoPlayerModal");
        videoPlayer.insertBefore(videoScript, videoPlayer.chilldNodes);
      }

      if (hasInput) hasInput.focus();
      $(".wrapper-modal, #" + modal).toggleClass("-active");
      $("body").toggleClass("_overflow-hidden");
    });

    $(document).on("click", ".actControlMapModal", function () {
      const mapModal = $(this).data("modal");

      $(".wrapper-modal.-inside, #" + mapModal).toggleClass("-active");
    });

    $(document).on("click", ".actCloseModal", function () {
      $(".wrapper-modal, .wrapper-modal.-inside, .modal-content").removeClass(
        "-active"
      );
      $("[data-menu=aside]").removeClass("-open");

      $("body").removeClass("_overflow-hidden");

      // remove iframe from tour's video
      $("#tourPlayerModal").find("iframe").remove();
    });

    $(document).on("click", ".actCloseModalNoRain", function () {
      const id = $(this).data("id");

      $(`#modalInfoNoRain${id}`).remove();
      $(`#modalInfoChart${id}`).remove();
    });

    $(document).on("click", ".act-control-mobile-search", function () {
      $(`[data-id='mobile-search']`).slideToggle("fast");
    });
  },
  /**
   * Requisita ao usuário a sua localização
   *
   * @param callback
   */
  getUserLocation: (callback, error) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(callback, error);
    }
  },
  /**
   * Muda comportamento da página se tiver banner background ativo
   *
   */
  renderBackgroundBanner: () => {
    let timer = setInterval(function () {
      if (
        !$(
          '[data-forecast-page="amanha"]' ||
            $('[data-forecast-page="agora"]' || $('[data-forecast-page="fds"]'))
        )
      ) {
        // percorre divs que podem conter mega destaque
        $('[data-id="bannerMegaFeaturedForecast"]').each(function () {
          // se tiver bg, o iframe assume width maior que 1
          if ($(this).children().children().prop("width") > 1) {
            // acrescenta opacidade para mostrar o bg
            $(this).parent().addClass("-opacity");
            // empurra conteúdo da página para ajustar ao banner
            $('[data-id="mainContainer"]').addClass("has-mega-featured");
            // remove primeiro mega banner quando o bg estiver ativado
            $('[data-id="wrapperFirstBanner"]').css("display", "none");
            // limpa o interval para não continuar percorrendo os banners
            clearInterval(timer);
            // remove a div do superbanner no topo
            $('[data-id="superBanner1"]').remove();
          }
        });
      }
    }, 2000);
  },
  /**
   * Varre divs de selo (patrocínio) e habilita as que tiverem banner ativo
   *
   */
  renderSeal: () => {
    setTimeout(() => {
      $("[data-selo]").each(function () {
        if ($(this).children().children() !== "script") {
          $(this).parent().addClass("_none");

          if (
            $(this).children().children().prop("width") &&
            $(this).css("display") !== "none"
          ) {
            $(this).parent().removeClass("_none");
          } else {
            $(this).parent().removeClass("_flex-md");
          }
        }
      });
    }, 3000);
  },
  /**
   * Controla modal de notícias dos usuários
   *
   */
  controlNewsModal: () => {
    $(document).on("click", "[data-open-article]", function () {
      const id = $(this).data("open-article");

      $("[data-article]").addClass("_none");
      $("[data-article=" + id + "]").removeClass("_none");
    });
  },
  /**
   * Controla eventos de scroll na página (lazyload)
   *
   */
  onScroll: () => {
    $("[data-map]").each(function () {
      Main.renderMap();
    });

    const stickBannerMobile = $("[data-banner=stickBannerMobile");

    let stickBannerMobileTop = 0;
    let stickBannerMobileEnd = 0;

    if (stickBannerMobile.length) {
      stickBannerMobileTop = stickBannerMobile.offset().top;
      stickBannerMobileEnd = $("[data-end-sticky-banner]").offset().top;
    }

    document.addEventListener(
      "scroll",
      function (e) {
        let scroll = $(window).scrollTop();

        if (stickBannerMobile.length) {
          /**
           * BEGIN: sticky banner mobile
           */
          if (scroll >= stickBannerMobileTop) {
            stickBannerMobile.addClass("_fixed");
          } else {
            stickBannerMobile.removeClass("_fixed");
          }

          if (scroll >= stickBannerMobileEnd + 60) {
            stickBannerMobile.addClass("-hidden");
          } else {
            stickBannerMobile.removeClass("-hidden");
          }
          /**
           * END: sticky banner mobile
           */
        }

        if (scroll > 300) {
          $('[data-banner="stickBanner"]').addClass("-active");
        } else {
          $('[data-banner="stickBanner"]').removeClass("-active");
        }

        $("[data-counter]").each(function () {
          let offset = $(this).offset().top - 300;
          let activated = $(this).hasClass("-activated");

          if (scroll > offset && !activated) {
            const total = $(this).data("total");

            $(this).addClass("-activated");
            $(this)
              .prop("counter", 0)
              .animate(
                { counter: total },
                {
                  duration: 2500,
                  easing: "swing",
                  step: function (now) {
                    $(this).text(Math.ceil(now));
                  },
                }
              );
          }
        });

        $("[data-map-lazyload]").each(function () {
          let offsetMap = $(this).offset().top - 500;
          let activatedMap = $(this).hasClass("-activated");

          if (scroll > offsetMap && !activatedMap) {
            $(this).addClass("-activated");
            Main.renderMap();
          }
        });

        $("[data-youtube-playlist]").each(function () {
          let offsetVideo = $(this).offset().top - 900;
          let activatedVideo = $(this).hasClass("-activated");

          if (scroll > offsetVideo && !activatedVideo) {
            let iframe = document.createElement("iframe");

            $(this).addClass("-activated");
            iframe.setAttribute(
              "src",
              "https://www.youtube.com/embed/videoseries?list=PLxmGWMXKfr9pS5844TEB2m9zwnzARvGNx"
            );
            iframe.setAttribute("width", "100%");
            iframe.setAttribute("heigth", "100%");
            iframe.setAttribute("frameborder", "0");

            $(this).append(iframe);
          }
        });
      },
      { passive: true }
    );
  },
  /**
   * Renderiza mapa
   * TODO: Retirar método do main e criá-lo na controller de Brazil e Forecast
   * @param lat
   * @param lon
   * @returns {boolean}
   */
  renderMap: async (lat = null, lon = null) => {
    const idCity = document
      .getElementById("mainContent")
      .getAttribute("data-id-city");
    const forecastPage = $("#mainContent").data("forecast-page");
    let options;

    if (forecastPage === "brasil") {
      options = {
        center: [-15.78, -47.93],
        zoom: 4,
      };
    }

    const { default: MapCabuloso } = await import(
      /* webpackChunkName: "mapLazyModule" */
      "./components/Map"
    );

    Main.mapInstance = new MapCabuloso("mapCabuloso", options);

    const { mapInstance } = Main;
    const { map } = mapInstance;

    if (lat && lon) {
      return void map.setView([lat, lon]);
    }

    const url = "/info?id=" + idCity;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        const cityData = JSON.parse(this.response);

        // In case it returns an empty response
        if (Array.isArray(cityData)) return;

        const { latitude, longitude, city, uf } = cityData;
        const latlng = [latitude, longitude];

        map.setView(latlng);
        mapInstance.addCityMarker(latlng, `${city} - ${uf}`);
      }
    };

    xhttp.open("GET", url, true);
    xhttp.send();
  },

  /**
   * Adiciona autocomplete de localidades para inputs necessários
   * TODO: Desmembrar método responsável pelo autocommplete do topo e mapas
   */
  autocompleteSearch: function () {
    const searchGeneral = new Autocomplete();
    const searchAddLocale = new Autocomplete();
    const searchGeolocation = new Autocomplete();
    const searchInMapElement = $("#searchInMap");
    const searchGeneralMobileElement = $("#mobileSearch");

    searchGeneral.create({
      input: $("#searchGeneral"),
      maxResult: 4,
      minLength: 3,
      allTypes: true,
    });

    if (searchGeneralMobileElement.length) {
      const searchGeneralMobile = new Autocomplete();
      searchGeneralMobile.create({
        input: searchGeneralMobileElement,
        maxResult: 4,
        minLength: 3,
      });
    }

    searchAddLocale.create({
      input: $("#searchAddLocale"),
      maxResult: 4,
      minLength: 3,
      onPress: (list) => {
        list.siblings(".autocomplete-list").css("height", "auto");
      },
    });

    searchGeolocation.create({
      input: $("#searchGeolocation"),
      maxResult: 4,
      minLength: 3,
      onPress: (list, el) => {
        list.siblings(".autocomplete-list").css("height", "auto");

        const locale = el.data("locale");
        const city = el.data("city");
        const name = el.data("name");
        const uf = el.data("uf");
        const domainName = window.location.hostname;

        document.cookie = `__geo_locale=${locale}; domain=.${domainName}`;
        document.cookie = `__geo_city=${city}; domain=.${domainName}`;
        document.cookie = `__geo_name=${name}; domain=.${domainName}`;
        document.cookie = `__geo_uf=${uf}; domain=.${domainName}`;

        location.reload();
      },
    });

    if (searchInMapElement.length) {
      const searchInMap = new Autocomplete();
      searchInMap.create({
        input: searchInMapElement,
        maxResult: 4,
        minLength: 3,
        onPress: (list, el) => {
          const name = el.data("name");
          const lat = el.data("lat");
          const lon = el.data("lon");
          const uf = el.data("uf");

          this.mapInstance.map.setView([lat, lon], 10);
          this.mapInstance.addCityMarker([lat, lon], `${name} - ${uf}`);

          $("[data-id=custom-mobile-menu]").removeClass("-opened");
        },
      });
    }
  },
  /**
   * Fecha banner
   */
  closeBanner: () => {
    $(document).on("click", "[data-close-banner]", function () {
      $(this).parent().remove();
    });
  },
  /**
   * Controla os banners fixos
   *
   */
  controlFixedSideBanner: () => {
    // se tiver banner fixo lateral
    if ($("#wrapperSideBanner").length) {
      document.addEventListener(
        "scroll",
        function (e) {
          const wrapperSideBanner = $("#wrapperSideBanner");
          const sideBanner = $("#sideBanner");
          const wrapperSideBannerTop = wrapperSideBanner.offset().top;
          const wrapperSideBannerHeight = wrapperSideBanner.height();
          const wrapperSideBannerTotal =
            wrapperSideBannerTop + wrapperSideBannerHeight;
          const sideBannerHeight = sideBanner.height();
          let scrollPosition = wrapperSideBannerTotal - sideBannerHeight;
          let windowTop = $(window).scrollTop();

          if (windowTop >= scrollPosition)
            sideBanner
              .addClass("-absolute-banner")
              .removeClass("-fixed-banner");
          else if (windowTop >= wrapperSideBannerTop)
            sideBanner
              .addClass("-fixed-banner")
              .removeClass("-absolute-banner");
          else
            sideBanner
              .removeClass("-fixed-banner")
              .removeClass("-absolute-banner");

          if (sideBanner.hasClass("-fixed-banner")) sideBanner.css("top", "0");
          else sideBanner.css("top", "auto");
        },
        { passive: true }
      );
    }
  },
  /**
   * Requisita informações do usuário logado e monta menu no header
   *
   */
  userInfo: () => {
    const isLogged = document.cookie.match(new RegExp("(^| )SESSID=([^;]+)"));
    const url = `/json/myclimatempo/user/info?${Math.random(1, 1000)}`;

    if (isLogged) {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          const response = JSON.parse(this.response);

          if (response) {
            let initials = `${response.name.charAt(0).toUpperCase()}`;

            $("#isnt-logged-content").remove();

            $("#is-logged-content")
              .removeClass("_none")
              .find("#user-initials")
              .html(initials);
            $("#is-logged-content").find("#user-name").html(`${response.name}`);
            $("#is-logged-content").find("#user-email").html(response.email);

            // verifica se está habilitada a previsão por e-mail
            let enabledEmailForecast = false;

            Object.keys(response.emailForecastPreferences).forEach(
              (key, index) => {
                if (response.emailForecastPreferences[key]) {
                  enabledEmailForecast = true;
                }
              }
            );

            if (!enabledEmailForecast) {
              //$('#popup-register').removeClass('-hidden')
              //$('#popup-register #form-isnt-logged').remove()
              //$('#popup-register #form-logged').removeClass('_none')
            }

            Main.renderWeatherNowByFavoritesLocalesBreadcrumb();
          } else {
            $("#is-logged-content").remove();
            $("#isnt-logged-content").removeClass("_none");

            //$('#popup-register').removeClass('-hidden')
          }
        }
      };

      xhttp.open("GET", url, true);
      xhttp.send();
    } else {
      $("#is-logged-content").remove();
      $("#isnt-logged-content").removeClass("_none");

      //$('#popup-register').removeClass('-hidden')
    }
  },
  /**
   * Modal do perfil do usuário localizado no header
   *
   */
  controlLoggedMenu: () => {
    $(document).on("click", ".act-control-logged-menu", function (e) {
      e.preventDefault();

      $("[data-modal=user-logged-menu]").toggleClass("_none");
    });
  },
  /**
   * Lista de localidades favoritas na barra de breadcrumb
   *
   */
  controlModalListBreadcrumb: () => {
    $(document).on(
      "click",
      ".act-control-favorites-locales-breadcrumb",
      function (e) {
        e.preventDefault();

        $("[data-id=list-favorites-locales-breadcrumb]").toggleClass("-active");
      }
    );
  },
  /**
   * Contabiliza cliques no GA nos elementos selecionados
   *
   */
  initMetrics: () => {
    $(document).ready(function () {
      $(".actTriggerGA").click(function () {
        const category = $(this).data("category");
        const label = $(this).data("label");
        const action = $(this).data("action");

        // ga('send', {
        //   hitType: 'event',
        //   eventCategory: category,
        //   eventAction: action,
        //   eventLabel: label
        // });
      });
    });
  },
  /**
   * Controla evento de click dos accordions
   * Forecast Weekend, Fifteen Days, Favorites Locales (My Climatempo)
   */
  controlAccordion: () => {
    $(document).on("click", ".actOpenAccordion", function (e) {
      if (['IMG', 'BUTTON'].includes(e.target.tagName)) return;

      const accordion = $(this).data("accordion");

      $("[data-id=accordion" + accordion + "]").toggleClass("_none");

      $(this).find(".accordion-icon").toggleClass("-active");
      $(this).find(".simple-arrow").toggleClass("-down");
      $(this).toggleClass("-down");
    });
  },
  /**
   * Render a list with forecast now for all favorites locales
   * Header in all pages
   */
  renderWeatherNowByFavoritesLocalesBreadcrumb: () => {
    const isLogged = document.cookie.match(new RegExp("(^| )SESSID=([^;]+)"));
    const url = `/json/myclimatempo/user/weatherNowByFavoritesLocales?${Math.random(
      1,
      1000
    )}`;

    if (isLogged) {
      const xhttp = new XMLHttpRequest();
      try {
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            try {
              const response = JSON.parse(this.response);

              response.forEach((item) => {
                let locale = item.data[0].locale;
                let weather = item.data[0].weather[0];
                let slug = Main.createSlug(locale.name);
                let acronym = locale.acronym;

                if (locale.acronym == "BR") acronym = locale.uf;

                const url = `${locale.city}/${slug}-${acronym.toLowerCase()}`;
                const cityName = `${locale.name}, ${acronym}`;

                // item = `<li class="item" style="z-index: 999;">
                //           <img class="icon" src="/dist/images/v2/svg/outline/${
                //             weather.icon
                //           }.svg" width="20" height="20" alt="Ícone de temperatura atual">
                //           <a href="/previsao-do-tempo/cidade/${url}" class="-white">
                //             ${cityName}
                //             /
                //             ${weather.temperature}&deg;
                //           </a>
                //         </li>`;

                item = `<li class="item" style="z-index: 999;">
                          <a href="/previsao-do-tempo/cidade/${url}" class="-white">
                            ${cityName}
                            /
                            ${weather.temperature}&deg;
                          </a>
                        </li>`;

                $("[data-id=list-favorites-locales-breadcrumb")
                  .append(item)
                  .removeClass("_none");
                $("[data-id=list-favorites-locales-arrow").removeClass("_none");
              });
            } catch (e) {}
          }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    }
  },

  /**
   * Create component with forecast now for geolocated city
   *
   */
  renderWeatherNowByGeolocation: (idlocale, idcity, refresh = false) => {
    const url = `/json/myclimatempo/user/weatherNow?idlocale=${idlocale}`;
    const xhttp = new XMLHttpRequest();

    try {
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          let response = JSON.parse(this.response);

          let locale = response.data.getWeatherNow[0].data[0].locale
          let weather = response.data.getWeatherNow[0].data[0].weather

          let slug = Main.createSlug(locale.city);
          let acronym = locale.ac;

          if (locale.ac == "BR") acronym = locale.uf;

          const url = `${locale.city}/${slug}-${acronym.toLowerCase()}`;

          // TODO: verificar se está na home
          if ($("[data-current-weather]").length > 0) {
            if (refresh) {
              //if (cookieCity !== `__geo_city=${idcity}`) {
              const domainName = window.location.hostname;

              document.cookie = `__geo_locale=${idlocale}; domain=.${domainName}`;
              document.cookie = `__geo_city=${idcity}; domain=.${domainName}`;
              document.cookie = `__geo_name=${locale.name}; domain=.${domainName}`;
              document.cookie = `__geo_uf=${locale.uf}; domain=.${domainName}`;

              location.reload();
              //}
            }
          }

          // geolocated city in array position 0
          const cityName = `${locale.city}, ${acronym}`;

          $("[data-id=geolocated]").find(".city").html(cityName);
          $("[data-id=geolocated]")
            .find(".temperature")
            .html(`${weather.temperature}&deg;`);
          $("[data-id=geolocated]")
            .find(".ico")
            .attr(
              "src",
              `/dist/images/v2/svg/outline/${weather.icon}.svg`
            );
          $("[data-id=geolocated]")
            .find(".link")
            .attr("href", `/previsao-do-tempo/cidade/${url}`);
          $("[data-id=geolocated]").removeClass("-hidden-geolocated");

          if ($("[data-current-weather]").length > 0) {
            $("#momento-localidade").html(cityName);
            $("#momento-icone").attr(
              "src",
              `/dist/images/v2/svg/outline/${weather.icon}.svg`
            );
            $("#momento-temperatura").html(`${weather.temperature}&deg;`);
            $("#momento-condicao").html(`${weather.condition}`);
            $("#momento-sensacao").html(`${weather.sensation}&deg;`);
            $("#momento-umidade").html(`${weather.humidity}%`);
            $("#momento-pressao").html(`${weather.pressure} hPa`);
            $("#momento-vento").html(`${weather.windVelocity} km/h&nbsp;`);
            $("#momento-atualizacao").html(
              `Atualizado às ${weather.date.substr(11, 5)}h`
            );

            $("[data-id=loadingCurrentWeather]").remove();
          }
        }
      };

      xhttp.open("GET", url, true);
      xhttp.send();
    } catch (e) {}
  },
  /**
   * Sync favorited locales from mobile device to web
   */
  syncFavLocalesPushNotification: () => {
    const isLogged = document.cookie.match(new RegExp("(^| )SESSID=([^;]+)"));
    const isSyncToday = document.cookie.match(
      new RegExp("(^| )syncToday=([^;]+)")
    );

    if (isLogged && !isSyncToday) {
      const url = `/json/myclimatempo/user/getChannels`;
      const xhttp = new XMLHttpRequest();
      const now = new Date();

      try {
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            const userChannels = JSON.parse(this.response);
            userChannels.map((channel) => {
              const topic = `BR_RAIN_${Main.convertRegion(channel.region)}_${
                channel.uf
              }_${channel.localeId}`;
              const enabled = channel.active ? 1 : 0;
              // Se inscreve no PushNews
              Main.pushSubscription(topic, enabled);
            });
          }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {}

      now.setTime(now.getTime() + 12 * 3600 * 1000); // lifetime: 12 hours
      document.cookie =
        "syncToday=true; expires=" + now.toUTCString() + "; path=/";
    }
  },
  convertRegion: (region) => {
    const dicionary = {
      n: "N",
      ne: "NE",
      e: "E",
      se: "SE",
      s: "S",
      so: "SW",
      o: "W",
      ne: "NW",
      co: "MW",
    };

    return dicionary[region.toLowerCase()]
      ? dicionary[region.toLowerCase()]
      : region.toUpperCase();
  },
  /**
   * Ask for geolocation position and get locale id
   *
   */
  userGeolocation: () => {
    // verifica se tem cookie
    let cookieCity,
      cookieLocale,
      cookieName,
      cookieUf = null;
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let temp = cookies[i].trim();
      if (temp.indexOf("__geo_city=") == 0)
        cookieCity = temp.substring(name.length, temp.length);
      if (temp.indexOf("__geo_locale=") == 0)
        cookieLocale = temp.substring(name.length, temp.length);
      if (temp.indexOf("__geo_name=") == 0)
        cookieName = temp.substring(name.length, temp.length);
      if (temp.indexOf("__geo_uf=") == 0)
        cookieUf = temp.substring(name.length, temp.length);
    }

    // if city in the cookie, get from cookie
    // if not, try to get by geolocation
    if (cookieCity && cookieLocale && cookieName && cookieUf) {
      const city = cookieCity.split("=")[1];
      const locale = cookieLocale.split("=")[1];
      const name = cookieName.split("=")[1];
      const uf = cookieUf.split("=")[1];

      Main.renderWeatherNowByGeolocation(locale, city);
      Main.dynamicLink(city, name, uf);
    } else {
      Main.getUserLocation(
        (position) => {
          const url = "/json/minha-localizacao";
          const xhttp = new XMLHttpRequest();

          xhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
              const { idlocale, idcity, city, uf } = JSON.parse(this.response)
                .data[0];

              Main.renderWeatherNowByGeolocation(idlocale, idcity, true);
              Main.dynamicLink(idcity, city, uf);
            }
          };

          xhttp.open("POST", url, true);
          xhttp.setRequestHeader(
            "Content-type",
            "application/x-www-form-urlencoded"
          );
          xhttp.send(
            `latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
          );
        },
        (error) => {
          // set São Paulo as default locale
          Main.renderWeatherNowByGeolocation(3477, 558);
          Main.dynamicLink(558, "São Paulo", "SP");
        }
      );
    }
  },
  /**
   * Create slug from string
   *
   */
  createSlug: (string) => {
    const slug = Slugify(string, {
      remove: /[ \']/g,
      url: true,
      lower: true,
    });

    return slug;
  },
  pushSubscription: (channel, value) => {
    // Send subscription to Push News
    IlabsPush.sendCustomFilters({ [channel]: value });
  },
  /**
   * Create a dynamic link for menus
   */
  dynamicLink: (idcity, name, uf) => {
    const slugBody = $("body").data("slug");
    const slugBreadcrumb = `${idcity}/${Main.createSlug(
      name
    )}-${uf.toLowerCase()}`;

    const slug = slugBody ? slugBody : slugBreadcrumb;

    $("[data-dynamic-link]").each(function () {
      let href = $(this).data("link").replace("{slug}", slug);
      $(this).attr("href", href);
    });
  },
  dynamicNewsLink: () => {
    $("[data-dynamic-news-link]").each(function () {
      const widthScreen = $(window).width();
      const href = $(this).data("link");
      let prefix = "/noticia";

      if (href.startsWith('https://agroclima.climatempo.com.br/')) {
        console.log(href)
        $(this).attr('href', `${href}`)
      }else {
        $(this).attr("href", `${prefix}/${href}`);
      }
    });

    $("[data-dynamic-agroclima-news-link]").each(function () {
      const widthScreen = $(window).width();
      const href = $(this).data("link");
      let prefix = "/agroclima/noticia";

      if (widthScreen < 1024) {
        prefix += "/amp/v2";
      }

      $(this).attr("href", `${prefix}/${href}`);
    });
  },
  /**
   * Carrosel de notícias em tempo real
   * Forecast Today
   */
  controlCarousel: () => {
    const owl = $('[data-carousel-name="realTime"]');

    $(".-owl-next").click(function () {
      owl.trigger("next.owl.carousel");
    });

    $(".-owl-prev").click(function () {
      owl.trigger("prev.owl.carousel");
    });
  },
  init: () => {
    Main.initCarousel();
    Main.userInfo();
    Main.scrollUp();
    Main.controlModal();
    Main.renderBackgroundBanner();
    Main.renderSeal();
    Main.controlNewsModal();
    Main.onScroll();
    Main.autocompleteSearch();
    Main.closeBanner();
    Main.controlFixedSideBanner();
    Main.controlMobileMenu();
    Main.controlLoggedMenu();
    Main.initMetrics();
    Main.controlAccordion();
    Main.userGeolocation();
    Main.controlModalListBreadcrumb();
    Main.syncFavLocalesPushNotification();
    Main.dynamicNewsLink();
    Main.controlCarousel();
    alertNews.getNewestAlert();
    alertNews.closeAlert();
    header.controlMenu();
    common.autocompleteSearchV2();
    common.setAlertStyle();
  },
};

export default Main;
