import 'lazysizes'
import Slugify from 'slugify'
import Autocomplete from './autocomplete'

const common = {

  /**
   * Create slug from string
   *
   */
  createSlug: (string) => {
    const slug = Slugify(string, {
      remove: /[ \']/g,
      url: true,
      lower: true
    })

    return slug
  },
  /**
   * Controla eventos de scroll na página (lazyload)
   *
   */
  onScroll: () => {
    const screenHeight = $(window).height()

    const stickyDesktop = $('[data-banner=stickBannerDesktop')
    const stickyMobile = $('[data-banner=stickBannerMobile')
    const stickyMobileBottom = $('[data-banner=stickBannerMobileBottom')
    const fakeSticky = $('[data-banner=fake-sticky]')

    let beginStickyMobile = 0
    let endStickyMobile = 0
    let beginStickyMobileBottom = 0
    let endStickyMobileBottom = 0

    // verifica se tem sticky na página
    if (stickyMobile.length) {
      beginStickyMobile = stickyMobile.offset().top
      endStickyMobile = $('[data-end-sticky-banner-mobile]').offset().top
    }

    if (stickyMobileBottom.length) {
      beginStickyMobileBottom = $('[data-end-sticky-banner-mobile]').offset().top + 60
      endStickyMobileBottom = $('.footer-site').offset().top - 300
    }

    document.addEventListener('scroll', function (e) {
      let scroll = $(window).scrollTop()

      if (stickyDesktop.length) {
        if (scroll > 200) stickyDesktop.removeClass('-hidden');
      }

      // verifica se tem sticky na página
      if (fakeSticky.length) {
        let fakeStickyPosition = $('[data-control-fake-sticky]').offset().top

        scroll >= (fakeStickyPosition - (screenHeight - 90))
          ? fakeSticky.find('.-close-banner').removeClass('_flex')
          : fakeSticky.find('.-close-banner').addClass('_flex')

        if (scroll > 200) fakeSticky.removeClass('-hidden')
      }

      if (stickyMobile.length) {
        scroll >= beginStickyMobile
          ? stickyMobile.addClass('_fixed')
          : stickyMobile.removeClass('_fixed')

        scroll >= (endStickyMobile + 60)
          ? stickyMobile.addClass('-hidden')
          : stickyMobile.removeClass('-hidden')
      }

      if (stickyMobileBottom.length) {
        scroll >= beginStickyMobileBottom ? stickyMobileBottom.addClass('_fixed') : stickyMobileBottom.removeClass('_fixed')
        scroll >= endStickyMobileBottom ? stickyMobileBottom.addClass('-hidden') : stickyMobileBottom.removeClass('-hidden')
      }

      $('[data-counter]').each(function () {
        let offset = $(this).offset().top - 100
        let activated = $(this).hasClass('-activated')

        if (scroll > offset && !activated) {
          const total = $(this).data('total')

          $(this).addClass('-activated')

          $(this).prop('counter', 0).animate({ counter: total }, {
            duration: 2500,
            easing: 'swing',
            step: function (now) { $(this).text(Math.ceil(now)) }
          })
        }
      })
    }, { passive: true })
  },

  /**
   * Controla abertura da pesquisa mobile
   *
   */
  controlMobileSearch: () => {
    $(document).on('click', '.act-control-mobile-search', function () {
      $(`[data-id='mobile-search']`).slideToggle('fast')
    })
  },

  /**
   * Fecha um banner
   *
   */
  closeBanner: () => {
    $('[data-close-banner]').click(function () {
      $(this).parent().remove()
    })

    $('[data-close-fake-sticky]').click(function () {
      $(this).parent().parent().addClass('_relative')
      $(this).remove()
    })
  },

  /**
   * Adiciona autocomplete de localidades para inputs necessários
   */
  autocompleteSearch: function () {
    // const searchGeneral = new Autocomplete()
    // const searchGeneralMobile = new Autocomplete()

    // const isAgro = $('#searchGeneral').data('is-agro')
    // const isAgroMobile = $('#searchGeneralMobile').data('is-agro')

    // searchGeneral.create({
    //   input: $('#searchGeneral'),
    //   maxResult: 4,
    //   minLength: 3,
    //   allTypes: true,
    //   isAgro
    // })

    // searchGeneralMobile.create({
    //   input: $('#searchGeneralMobile'),
    //   maxResult: 4,
    //   minLength: 3,
    //   isAgro: isAgroMobile
    // })
  },

  autocompleteSearchV2: () => {
    const isDesktop = $(document).width() > 1199

    const desktopAutocomplete = $('#desktopSearchAutocomplete')
    const mobileAutocomplete = $('#mobileSearchAutocomplete')

    if (isDesktop) mobileAutocomplete.remove()
    else desktopAutocomplete.remove()

    const mobileSearchButton = $('#bt_modalSearch_mobile')

    const input = isDesktop ? $('#searchGeneral') : $('#mobileSearchInput')

    const ul = $('#autocompleteSuggestions')
    const header = $('#autocompleteHeader')
    const emptyMessage = $('#autocompleteEmptyHistory')
    const clearButton = $('#autocompleteHeaderClearButton')
    const myLocationButton = $('#autocompleteSearchMyLocation')

    const getSeacherdCities = () => JSON.parse(localStorage.getItem('searchedCities') || '[]')

    const showStoredPlaces = (e) => {
      const inputValue = e.target.value.trim()

      desktopAutocomplete.css('display', 'flex')

      if (inputValue.length >= 3 || !getSeacherdCities().length) return

      header.css('display', 'flex')

      if (getSeacherdCities().length) {
        emptyMessage.hide()
        ul.show()
      } else {
        emptyMessage.css('display', 'flex')
      }

      ul.empty()

      getSeacherdCities().forEach((city, index) => {
        const li = $('<li></li>')
        const a = $('<a></a>')
        const span = $('<span></span>')
        const img = $('<img src="/dist/images/v2/svg/history.svg" alt="Histórico">')

        span.text(city.label)
        a.append(span)
        a.append(img)
        li.append(a)

        a.attr('href', city.href)
        a.attr('data-id', `Menu_Search_Item_HistoryCityNavLink-${String(index + 1).padStart(2, '0')}`)
        a.attr('data-lat', city.dataLat)
        a.attr('data-lon', city.dataLon)
        a.attr('data-name', city.dataName)
        a.attr('data-uf', city.dataUf)
        a.attr('data-locale', city.dataLocale)
        a.attr('data-city', city.dataCity)

        ul.append(li)
      })

      ul.css('padding-top', '0')
      clearButton.show()
    }

    input.on('focus', showStoredPlaces)

    input.on('input', async (e) => {
      const inputValue = e.target.value.trim()

      if (inputValue.length === 0) {
        ul.empty()

        if (getSeacherdCities().length) {
          showStoredPlaces(e)
          return
        }

        header.css('display', 'flex')
        emptyMessage.css('display', 'flex')
        ul.hide()
        return
      }

      if (inputValue.length < 3) return

      const requestedCities = await new Promise((resolve, reject) => {
          $.ajax({
          url: '/json/busca-por-nome',
          type: "POST",
          dataType: "json",
          cache: true,
          context: this,
          data: { name: inputValue },
          success: resolve,
          error: reject
        })
      })

      const slicedSuggestions = requestedCities.map(({ response: { data }, type }) => ({
        places: data.slice(0, 5),
        type
      }))

      header.css('display', 'none')
      emptyMessage.css('display', 'none')
      ul.css('display', 'block')
      ul.empty()

      const keyByType = {
        city: 'city',
        beach: 'beach',
        airport: 'airport',
      }

      const idKeyByType = {
        city: 'idcity',
        beach: 'idbeach',
        airport: 'idairport',
      }

      const pathByType = {
        city: 'cidade',
        beach: 'litoral',
        airport: 'aeroporto',
      }

      const titleByType = {
        city: 'Cidades',
        beach: 'Praias',
        airport: 'Aeroportos',
      }

      let index = 0

      slicedSuggestions.forEach(({ places, type }) => {
        const placeTypeH6 = $(`<h6>${titleByType[type]}</h6>`)

        if (places.length) ul.append(placeTypeH6)

        places.forEach((place) => {
          index++

          const li = $('<li></li>')
          const a = $('<a></a>')
          const span = $('<span></span>')

          const uf = place.uf === 'ND' && place.ac ? place.ac : place.uf
          const label = `${place[keyByType[type]]} - ${uf}`

          span.text(label)
          a.append(span)
          li.append(a)

          const href = `/previsao-do-tempo/${pathByType[type]}/${place[idKeyByType[type]]}/${common.createSlug(place[keyByType[type]])}-${uf.toLowerCase()}`

          a.attr('href', href)
          a.attr('data-id', `Menu_Search_Item_CityNavLink-${String(index).padStart(2, '0')}`)
          a.attr('data-lat', place.latitude)
          a.attr('data-lon', place.longitude)
          a.attr('data-name', place[keyByType[type]])
          a.attr('data-uf', uf)
          a.attr('data-locale', place.idlocale)
          a.attr('data-city', place.idcity)

          a.on('click', () => {
            if (getSeacherdCities().some((storedPlace) => storedPlace.label === label)) return

            const newSearchedCities = [
              {
                label,
                href,
                dataLat: place.latitude,
                dataLon: place.longitude,
                dataName: place[keyByType[type]],
                dataUf: uf,
                dataLocale: place.idlocale,
                dataCity: place.idcity
              },
              ...getSeacherdCities()
            ].slice(0, 5)

            localStorage.setItem('searchedCities', JSON.stringify(newSearchedCities))
          })

          ul.append(li)

          if (isDesktop) ul.css('padding-top', '8px')
        })
      })
    })

    input.on('blur', (e) => {
        if (e.relatedTarget?.id === clearButton[0].id) {
          input.trigger('focus')
          return
        }

        setTimeout(() => {
          desktopAutocomplete.hide()
        }, 200)
    })

    clearButton.on('click', () => {
      localStorage.setItem('searchedCities', JSON.stringify([]))
      ul.hide()
      emptyMessage.css('display', 'flex')
      clearButton.hide()
      input.trigger('focus')
    })

    mobileSearchButton.on('click', () => {
      desktopAutocomplete.remove()

      if (mobileAutocomplete.css('display') === 'flex') mobileAutocomplete.hide()
      else mobileAutocomplete.css('display', 'flex')

      input.trigger('focus')
    })

    const handleUserGeolocation = async (geolocationPosition) => {
      const { coords } = geolocationPosition

      const requestedCity = await new Promise((resolve, reject) => {
          $.ajax({
          url: '/json/minha-localizacao',
          type: "POST",
          dataType: "json",
          cache: true,
          context: this,
          data: { latitude: coords.latitude, longitude: coords.longitude },
          contentType: 'application/x-www-form-urlencoded',
          success: resolve,
          error: reject
        })
      })

      const { city, uf, idcity } = requestedCity.data[0]

      const href = `/previsao-do-tempo/cidade/${idcity}/${common.createSlug(city)}-${uf.toLowerCase()}`

      window.location = href
    }

    const redirectToSaoPaulo = () => {
      window.location = '/previsao-do-tempo/cidade/558/saopaulo-sp'
    }

    myLocationButton.on('click', () => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          handleUserGeolocation,
          redirectToSaoPaulo,
          {
            enableHighAccuracy: true,
            maximumAge: 3000,
          }
        )
      }
    })
  },

  /**
   * Create a dynamic link for menus
   */
  dynamicLink: (idcity, name, uf = '') => {
    const slugBody = $('body').data('slug')
    const slugBreadcrumb = `${idcity}/${common.createSlug(name)}-${uf.toLowerCase()}`

    const slug = slugBody ? slugBody : slugBreadcrumb

    $('[data-dynamic-link]').each(function () {
      let href = $(this).data('link').replace('{slug}', slug)
      $(this).attr('href', href)
    })

    $('[data-dynamic-news-link]').each(function () {
      // const widthScreen = $(window).width()
      const href = $(this).data('link')

      let prefix = '/noticia'
      if (href.startsWith('https://agroclima.climatempo.com.br/')) {
        console.log(href)
        $(this).attr('href', `${href}`)
      } else {
        $(this).attr('href', `${prefix}/${href}`)
      }
    })

    $('[data-dynamic-agroclima-news-link]').each(function () {
      const widthScreen = $(window).width()
      const href = $(this).data('link')
      let prefix = '/agroclima/noticia'

      if (widthScreen < 1024) {
        prefix += '/amp/v2'
      }

      $(this).attr('href', `${prefix}/${href}`)
    })
  },

  /**
   * Controla abertura dos subitens no menu mobile
   *
   */
  controlMobileMenu: () => {
    $(document).on('click', '.actControlMobileMenu', function () {
      const menu = $(this).data('menu')
      const after = $(this).data('after')

      $('#listSubMenusMobile.clone').remove()

      let $area = $('#listSubMenusMobile').clone().addClass('clone')

      $(`[data-sub-menu=${menu}]`).each(function () {
        let item = $(this).clone()

        $area.append(item)
      })

      $('.item-mobile').addClass('-opacity')
      $(this).removeClass('-opacity')

      $(`[data-index-menu=${after}]`).after($area)
    })
  },

  /**
   * Renderiza banner em modais e mapas
   * All Forecasts
   */
  // renderBanner: () => {
  //   $(document).on('click', '.act-render-banner', function() {
  //     if ($(this).hasClass('-rendered')) return

  //     const slots = window.innerWidth < 1024 ? $(this).data('slots-mobile') : $(this).data('slots-desk')

  //     if (!slots) return

  //     slots.forEach((slot) => {
  //       const definedSlot = googletag.defineSlot(`/1030473/${slot.adunit}`, slot.sizes, `${slot.adunit}`).addService(googletag.pubads())

  //       var div = document.createElement('div')
  //       div.id = definedSlot.getSlotElementId()

  //       if (!document.getElementById(`${slot.adunit}_ref`)) return
  //       document.getElementById(`${slot.adunit}_ref`).appendChild(div)

  //       pbjs.que.push(function() {
  //         pbjs.requestBids({
  //           timeout: 3000,
  //           adUnitCodes: [slot.adunit],
  //           bidsBackHandler: function() {
  //             pbjs.setTargetingForGPTAsync([slot.adunit])
  //             googletag.pubads().refresh([definedSlot])
  //             googletag.display(definedSlot)
  //           }
  //         })
  //       })
  //     })

  //     const banner = $(this).data('banner')
  //     $(`[data-banner=${banner}]`).addClass('-rendered')
  //   })
  // },

  /**
   * Contabiliza cliques no GA nos elementos selecionados
   *
   */
  initMetrics: () => {
    $(document).ready(function () {

      $('.actTriggerGA').click(function () {
        const category = $(this).data('category')
        const label = $(this).data('label')
        const action = $(this).data('action')

        // ga('send', {
        //   hitType: 'event',
        //   eventCategory: category,
        //   eventAction: action,
        //   eventLabel: label
        // });
      });

    })
  },

  /**
   * Identifica na aba principal em qual página de previsão o usuário está
   * All Forecasts
   */
  activeTab: () => {
    const tab = $('[data-page=has-tab]').data('tab')
    $('[data-item="' + tab + '"]').addClass('-active')
  },

  /**
   * Identifica na aba principal em qual pagina de previsão o usuário está (MOBILE)
   * All Forecasts
   */
  activeMobileTab: () => {
    const tab = $('[data-page=has-tab]').data('tab')

    if (tab) {
      const left = $('[data-navigation="' + tab + '"]').addClass('-active').offset().left

      const innerWidth = window.innerWidth
      const scrollLeft = (innerWidth / 2) - 60
      const fixedNavigation = document.getElementById('fixedNavigation')

      fixedNavigation.addEventListener('scroll', (ev) => {
        let scroll = ev.target.scrollLeft
        const left = document.getElementById('sideNavigatorLeft')
        const right = document.getElementById('sideNavigatorRight')

        if (scroll < 50) left.classList.add('-hidden')
        else left.classList.remove('-hidden')

        if (scroll > 350) right.classList.add('-hidden')
        else right.classList.remove('-hidden')
      }, { passive: true })

      fixedNavigation.scrollTo({
        left: left - scrollLeft,
        behavior: 'smooth'
      })
    }
  },
  /**
   * Dá compatibilidade em novos tamanhos de imagens do climapress
   */
  fallbackImage: () => {
    $('.fallback').on("error", function () {
      const imageElement = $(this)
      const imageFallback = imageElement.data('fallback')

      if (imageFallback) {
        imageElement.attr('src', imageFallback)
      }
    });
  },

  /**
   * Adiciona o atributo alt nas imagens de anuncio vindas do GTM
   */

  insertImgAlt: () => {
    document.addEventListener("DOMContentLoaded", function () {
      function adicionarAltNasImagens() {
        const imagens = document.querySelectorAll("img");
        imagens.forEach(function (img, index) {
          if (!img.hasAttribute('alt')) {
            img.setAttribute('alt', 'Imagem de anuncio: ' + (index + 1));
          }
        });
      }

      const observer = new MutationObserver(function (mutationsList) {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(function (node) {
              if (node.tagName === 'IMG' && !node.hasAttribute('alt')) {
                node.setAttribute('alt', 'Imagem de anuncio');
              } else if (node.querySelectorAll) {
                node.querySelectorAll('img').forEach(function (img) {
                  if (!img.hasAttribute('alt')) {
                    img.setAttribute('alt', 'Imagem de anuncio');
                  }
                });
              }
            });
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      adicionarAltNasImagens();
    }
    )
  },

  /**
   * Adiciona o atributo alt nas imagens de anuncio vindas do GTM
   */

  insertIframeTitle: () => {
    document.addEventListener("DOMContentLoaded", function () {

      function adicionarTitleNosIframes() {
        const iframes = document.querySelectorAll("iframe");
        iframes.forEach(function (iframe, index) {
          if (!iframe.hasAttribute('title')) {
            iframe.setAttribute('title', 'Iframe number: ' + (index + 1));
          }
        });
      }

      const observer = new MutationObserver(function (mutationsList) {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(function (node) {
              if (node.tagName === 'IFRAME' && !node.hasAttribute('title')) {
                node.setAttribute('title', 'Titulo do Iframe');
              } else if (node.querySelectorAll) {
                node.querySelectorAll('iframe').forEach(function (iframe,index) {
                  if (!iframe.hasAttribute('title')) {
                    iframe.setAttribute('title', 'Iframe number: ' + (index + 1));;
                  }
                });
              }
            });
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      adicionarTitleNosIframes();
    }
    )
  }

}

export default common
