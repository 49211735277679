import IMask from 'imask';
import TinyDatePicker from 'tiny-date-picker';
import Main from './main';

const MyClimatempo = {
  activeTab: () => {
    const tab = $('[data-page=myClimatempo]').data('tab');
    $('[data-item="' + tab + '"]').addClass('-active');

    let text, icon = ''

    switch (tab) {
      case 'favorites':
        text = 'Locais Favoritos'
        icon = 'heart'
        break
      case 'report-rain':
        text = 'Reportar Chuva'
        icon = 'report-rain'
        break
      case 'notifications':
        text = 'Notificações'
        icon = 'notification'
        break
      case 'configurations':
        text = 'Configurações'
        icon = 'configuration'
        break
      case 'premium':
        text = 'Premium'
        icon = 'premium'
        break
      default:
        text = 'Locais Favoritos'
        icon = 'heart'
    }

    $('#selectedText').html(text)
    $('#selectedIcon').attr('src', `/dist/images/icon-${icon}.png`)
  },
  controlAsideMenu: () => {
    $(document).on('click', '.actControlAsideMenu', function(e) {
      e.preventDefault()

      $('[data-menu=aside]').toggleClass('-open')
      $('.wrapper-modal').toggleClass('-active')
    })
  },
  toggleDeleteIcon: () => {
    $('.actToggleDeleteIcon').on('click', function() {
      $('.icon-delete').toggleClass('_block')
      $('.icon-temp').toggleClass('_none')

      const text = $(this).text() == 'Deletar' ? 'Cancelar' : 'Deletar'

      $(this).text(text)
    })
  },
  controlPasswordVisible: () => {
    $(document).on('click', '.actControlPasswordVisible', function(e) {
      e.preventDefault()

      const input = $(this).siblings('input')

      $(this).toggleClass('fa-eye-slash').toggleClass('fa-eye')

      let type = input.attr('type')

      type = (type === 'password') ? 'text' : 'password'

      input.prop('type', type)
    })
  },
  controlDeleteAccount: () => {
    $(document).on('click', '#btnDeleteAccount', function(e) {
      window.location.href = "https://climatempo.activehosted.com/f/21";
    })

    // $(document).on('click', '.actDeleteAccount', function(e) {
    //   const url = `/json/meu-climatempo/deletar-conta`
    //   const reasonMessage = $('#reason-message').val()
    //   const xhttp = new XMLHttpRequest()
    //   const isDisabled = $(this).hasClass('disabled')
    //   const user = $('#btnDeleteAccount').data('user')
    //   let formData = new FormData()

    //   formData.append('user', JSON.stringify(user))
    //   formData.append('reason', reasonMessage)
    //   $(this).text('Aguarde...')
    //   e.preventDefault()

    //   try {
    //     if(!isDisabled) {
    //       xhttp.onreadystatechange = function() {
    //         if (this.readyState == 4 && this.status == 200) {
    //           const response = JSON.parse(this.response)

    //           if (response != 202) {
    //             $('#btnDeleteAccount').val('Houve um erro').prop('disabled', true);
    //           } else {
    //             // Email successfully sent
    //             document.cookie = `__deletedAccount=true; domain=.${window.location.hostname}; path=/`
    //             $('#btnDeleteAccount').val('Enviado com sucesso').prop('disabled', true);
    //           }
    //           $('#modalDeleteAccount, .wrapper-modal').toggleClass('-active')
    //         }
    //       }
    //     }
    //   } catch (e) {
    //     $('#btnDeleteAccount').val('Houve um erro').prop('disabled', true);
    //     $('#modalDeleteAccount, .wrapper-modal').toggleClass('-active')
    //   }
    //   $(this).addClass('disabled')
    //   xhttp.open("POST", url, true)
    //   xhttp.send(formData)
    // })
  },
  userRegister: () => {
    $('#btnSendUserRegister').on('click', (e) => {
      const accepted = $('#privacy_policy').prop("checked");
      if(accepted){
        $("#messageAcceptedPrivacyPolicy").html('');
        const formInputs = $('#formSendUserRegister').find(':input:not(:submit)');
        const name = formInputs[0].value;
        const email = formInputs[1].value;
        const password = formInputs[2].value;
        const source = formInputs[3].value;
  
        let url = `/json/myclimatempo/user/register?name=${name}&email=${email}&password=${password}`;
        if (source === 'email') url = `/json/myclimatempo/user/activatePreRegister?name=${name}&email=${email}&password=${password}`
  
        const xhttp = new XMLHttpRequest();
        const messageStatusRegister = $('#messageStatusRegister');
        const btnSendUserRegister = $("#btnSendUserRegister");
        const btnValue = btnSendUserRegister.val()
  
        e.preventDefault();
        btnSendUserRegister.prop("disabled", true).val("aguarde...");
  
        try {
          xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
              const response = JSON.parse(this.response);
              // Something goes wrong on server side
              if (response.message) {
                $("#messageStatusMyClimatempo").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>Tente mais tarde. <br> O Meu Climatempo está indisponível no momento.')
              } else {
                if (source === 'email') {
                  location.reload()
                } else {
                  $('#formSendUserRegister').addClass("_none");
                  $('#register-msg-success').html(`<h1 class="_center -font-26 -gray-dark-3 _padding-t-30">Verifique seu endereço de e-mail</h1>
                  <p class="_center -font-16 -gray-dark-3 _margin-t-10 _padding-b-50">Para completar seu cadastro, você precisará   verificar seu endereço de e-mail <b>${email}</b> e confirmar a sua propriedade. </p>`)
                }
              }
  
              btnSendUserRegister.prop("disabled", false).val(btnValue);
            }
          };
          xhttp.open("GET", url, true);
          xhttp.send();
        } catch (e) {
          $("#messageStatusMyClimatempo").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>Tente mais tarde. <br> O Meu Climatempo está indisponível no momento.')
        }
      } else {
        $("#messageAcceptedPrivacyPolicy").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i> Para se cadastrar é necessário aceitar os termos e condições');
      }
    });
  },
  editUserData: () => {
    $('#formEditUserInfo').on('submit', (e) => {
      const formInputs = $('#formEditUserInfo').find(':input:not(:submit)');
      const name = formInputs[0].value;
      const birthday = formInputs[1].value;
      const gender = formInputs[2].value;
      const maritalStatus = formInputs[3].value;
      const children = formInputs[4].value;
      const formationCode = formInputs[5].value;
      const formationName = $('#formationSelect option:selected').text();
      const professionCode = formInputs[6].value;
      const professionName = $('#professionSelect option:selected').text();
      const phone = formInputs[7].value;
      const cityUf = formInputs[8].value;
      const cityLocaleId = formInputs[9].value;
      const cityName = $('#citySelect option:selected').text();
      const url = `/json/myclimatempo/user/edit?name=${name}&birthday=${birthday}&gender=${gender}&maritalStatus=${maritalStatus}&children=${children}&formationCode=${formationCode}&formationName=${formationName}&professionCode=${professionCode}&professionName=${professionName}&phone=${phone}&cityLocaleId=${cityLocaleId}&cityName=${cityName}&cityUf=${cityUf}`;
      const xhttp = new XMLHttpRequest();
      const messageStatusRegister = $('#messageStatusRegister');
      const btnSendUserRegister = $("#btnSendUserRegister");

      e.preventDefault();
      btnSendUserRegister.prop("disabled", true);

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              messageStatusRegister.html("Houve um erro, tente mais tarde");
            }
          }
          btnSendUserRegister.prop("disabled", false);
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    });
  },
  editPassword: () => {
    $('#formEditPassword').on('submit', (e) => {
      const formInputs = $('#formEditPassword').find(':input:not(:submit)');
      const oldPass = encodeURIComponent(formInputs[0].value);
      const newPass = encodeURIComponent(formInputs[1].value);
      const repeatPass = encodeURIComponent(formInputs[2].value);
      const url = `/json/myclimatempo/user/editPassword?oldPass=${oldPass}&newPass=${newPass}&repeatPass=${repeatPass}`;
      const xhttp = new XMLHttpRequest();
      const btnEditPassword = $('#btnEditPassword');
      const notEqualsPasswordMessage = $('#notEqualsPasswordMessage')
      const notEqualsOldPasswordMessage = $('#notEqualsOldPasswordMessage')
      const inputRepeatPass = $('#inputRepeatPass')
      const inputNewPass = $('#inputNewPass')
      const inputOldPass = $('#inputOldPass')

      notEqualsPasswordMessage.remove()
      notEqualsOldPasswordMessage.remove()
      inputRepeatPass.removeClass('-error')
      inputNewPass.removeClass('-error')
      inputOldPass.removeClass('-error')

      e.preventDefault();

      // valid if new pass is equal to repeated pass
      if (newPass == repeatPass) {
        try {
          xhttp.onreadystatechange = function() {

            if (this.readyState == 4 && this.status == 200) {
              const response = JSON.parse(this.response);

              if (response.message) {
                //  if inputs old pass is different of current pass of BD
                if (response.message.indexOf('1100') !== -1) {
                  inputOldPass.after(`<span class="messages -error _left _margin-t-5" id="notEqualsOldPasswordMessage">Hummm, não é a senha correta. Por favor tente novamente ou <a href="/redir/recuperar-senha" class="-blue">recupere sua senha</a>.</span>`)
                  inputOldPass.addClass('-error')
                }
              } else {
                // password has changed and open modal indicating the successfully message
                $('.wrapper-modal, #modalNewPassword').addClass('-active');
                inputOldPass.val('')
                inputNewPass.val('')
                inputRepeatPass.val('')
              }
            }

            btnEditPassword.prop("disabled", false);
          };

          xhttp.open("GET", url, true);
          xhttp.send();
        } catch (e) {
          messageStatusRegister.html("Houve um erro, tente mais tarde");
        }
      } else {
        // if new pass is different repeated pass, create a span and show error message to user
        inputRepeatPass.after(`<span class="messages -error _left _margin-t-5" id="notEqualsPasswordMessage">As senhas não coincidem</span>`)
        inputRepeatPass.addClass('-error')
        inputNewPass.addClass('-error')
      }
    });
  },
  controlModalEditPass: () => {
    const height = $('#modalNewPassword').height();
    const half = (height / 2) * -1;

    $('#modalNewPassword').css('margin-top', half);
    $('#modalNewPassword').addClass('_top-50-perc');
  },
  passwordRecover: () => {
    $('#btnSendPasswordRecover').on('click', (e) => {
      const formInputs = $('#formSendPasswordRecover').find(':input:not(:submit)');
      const email = formInputs[0].value;
      const url = `/json/myclimatempo/user/passwordRecover?email=${email}`;
      const xhttp = new XMLHttpRequest();
      const messageStatusRecover = $('#messageStatusRecover');
      const btnSendUserLogin = $("#btnSendPasswordRecover");
      const btnValue = btnSendUserLogin.val()

      e.preventDefault();
      btnSendUserLogin.prop("disabled", true).val("aguarde...");

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              messageStatusRecover.html("Usuário não encontrado");
              messageStatusRecover.addClass('-error')
            } else {
              $('#formSendPasswordRecover').addClass("_none");
              $('#recover-msg-success').html(`<h1 class="_center -font-26 -gray-dark-3 _padding-t-30">Senha redefinida com sucesso</h1>
              <p class="_center -font-16 -gray-dark-3 _margin-t-10 _padding-b-50">Enviamos um e-mail com sua nova senha.</p>`)
            }

            btnSendUserLogin.prop("disabled", false).val(btnValue);
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRecover.html("Houve um erro, tente mais tarde");
      }
    });
  },
  userLoginEmail: () => {
    $('#btnSendUserLogin').on('click', (e) => {
      const formInputs = $('#formSendUserLogin').find(':input:not(:submit)');
      const email = formInputs[0].value;
      const password = encodeURIComponent(formInputs[1].value);
      const url = `/json/myclimatempo/user/login?email=${email}&password=${password}`;
      const xhttp = new XMLHttpRequest();
      const messageStatusLogin = $('#messageStatusLogin');
      const btnSendUserLogin = $("#btnSendUserLogin");
      const btnValue = btnSendUserLogin.val()

      e.preventDefault();
      btnSendUserLogin.prop("disabled", true).val("aguarde...");
      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'event' : 'error_hit',
                'name' : 'login_general-input-error'
              })
              $("#messageStatusLoginPassword").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>Sua senha está incorreta. <a href="/recuperar-senha" style="text-decoration:underline; color: #E2251A;">Esqueceu a senha?</a>');
            } else {
              // Reload page, get user session and redirects user to profile page
              location.reload();
            }

            btnSendUserLogin.prop("disabled", false).val(btnValue);
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event' : 'error_hit',
          'name' : 'login_general-server-error'
        })
        messageStatusLogin.html("Houve um erro, tente mais tarde");
      }
    });
  },
  removeUserFavoriteLocale: () => {
    $(document).on('click', '.actRemoveUserFavoriteLocale', function() {
      let locale = $(this).data('locale')

      const url = `/json/myclimatempo/user/removeFavoriteLocale?locale=${locale}`;
      const xhttp = new XMLHttpRequest();

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              messageStatusRegister.html("Houve um erro, tente mais tarde");
            } else {
              MyClimatempo.renderWeatherNowByFavoritesLocales()
              $('.actToggleDeleteIcon').text('Deletar')
            }
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    })
  },
  addUserFavoriteLocale: () => {
    $(document).on('click', '.actAddFavoriteLocale', function() {
      $('.wrapper-modal, #modalAddLocale').toggleClass('-active')
      $('body').toggleClass('_overflow-hidden')

      const locale = $(this).data('locale')
      const url = `/json/myclimatempo/user/addFavoriteLocale?locale=${locale}`;
      const xhttp = new XMLHttpRequest();

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            if (response.message) {
              messageStatusRegister.html("Houve um erro, tente mais tarde");
            } else {
              MyClimatempo.renderWeatherNowByFavoritesLocales()
            }
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    })
  },
  getWelcomeIntro: () => {
    const isLogged = document.cookie.match(new RegExp('(^| )SESSID=([^;]+)'));
    const url = `/json/myclimatempo/user/welcome?${Math.random(1, 1000)}`;

    if (isLogged) {
      const xhttp = new XMLHttpRequest();

      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const response = JSON.parse(this.response);

          // if user already have logged in Meu Climatempo
          if (response) {
            MyClimatempo.renderWeatherNowByFavoritesLocales()
          } else {
            MyClimatempo.setWelcomeIntro()

            $('#subtitleWelcome').removeClass('_none')
            $('#welcomeMyClimatempo').removeClass('_none')

            $('#loadingFavoritesLocales').addClass('_none')
          }
        }
      };
      xhttp.timeout = 30000;
      xhttp.open("GET", url, true);
      xhttp.send();
    }
  },
  renderWeatherNowByFavoritesLocales: () => {
    const url = `/json/myclimatempo/user/weatherNowByFavoritesLocales?${Math.random(1, 1000)}`;
    const xhttp = new XMLHttpRequest();

    $('#listFavoritesLocales').addClass('_none').html(``)
    $('#loadingFavoritesLocales').removeClass('_none')

    try {
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          try {
            //import('../json/selected-cities.json').then(({ default: json }) => {
              const response = JSON.parse(this.response)
              let card = null

              // Something goes wrong on server side
              if (response.length === 0 || response === undefined || response == null) {
                messageStatusRegister.html("Houve um erro, tente mais tarde");
              } else {
                const favoriteLocalesLength = response.length

                if (favoriteLocalesLength >= 5) $('#btnAddFavoriteLocale').addClass('_none')
                else $('#btnAddFavoriteLocale').removeClass('_none')

                if (favoriteLocalesLength) {
                  $('#btnDeleteFavoriteLocale').removeClass('_none')

                  response.forEach(item => { 
                    let locale = item.data[0].locale
                    let weather = item.data[0].weather

                    let acronym = locale.ac
                    if (locale.ac == 'BR') acronym = locale.uf

                    const cityName = `${locale.city}, ${acronym}`
                    const url = `${locale.idcity}/${String(locale.city).toLowerCase()}-${acronym.toLowerCase()}`

                    card = `
                            <li class="item">
                              <div class="_flex _justify-center _align-center">
                                <img src="/dist/images/v2/svg/outline/${weather.icon}.svg" class="icon-temp" alt="Ícone de temperatura atual">
                                <img src="/dist/images/delete-icon.png" class="icon-delete _none actRemoveUserFavoriteLocale" alt="Remover localidade favorita" data-locale="${locale.id}">
                                
                                <a href="/previsao-do-tempo/cidade/${url}" class="_flex _space-between _width-100-pct _margin-l-20">
                                  <div class="summary">
                                    <span class="city">${cityName}</span>
                                    <p class="description">${weather.condition}</p>
                                  </div> 
                                  
                                  <div class="wrapper-right">
                                    <p class="temperature">${weather.temperature}&deg;<span class="now">agora</span></p>
                                  </div>
                                </a>
                              </div>
                            </li> 
                            `
                    $('#listFavoritesLocales').append(card)
                  })
                } else {
                  $('#msgEmptyFavoritesLocales').removeClass('_none')
                }

                $('#welcomeMyClimatempo, #subtitleWelcome').remove()
                $('#loadingFavoritesLocales').addClass('_none')
                $('#listFavoritesLocales, #subtitleFavoriteList').removeClass('_none')
              }
            //})
          } catch (error) { }
        }
      };
      xhttp.timeout = 30000;
      xhttp.open("GET", url, true);
      xhttp.send();
    } catch (e) {
      messageStatusRegister.html("Houve um erro, tente mais tarde");
    }
  },
  weatherPreferenceController: () => {
    $(document).on('click', '.custom-range-slider', function() {
      const id = $(this).data('id')
      const value = $(this).val()
      let text = ''

      if (value == 1) text = "Não Gosto"
      else if (value == 2) text = "Gosto"
      else text = "Gosto Muito"

      $('#weatherPreference' + id).html(text)
    })
  },
  userWeatherPreferences: () => {
    $('#formSendUserWeatherPreferences').on('submit', (e) => {
      const formInputs = $('#formSendUserWeatherPreferences').find(':input:not(:submit)');
      const rain = MyClimatempo.formatWeatherPreference(formInputs[0].value);
      const chill = MyClimatempo.formatWeatherPreference(formInputs[1].value);
      const heat = MyClimatempo.formatWeatherPreference(formInputs[2].value);

      const url = `/json/myclimatempo/user/weatherPreferences?rain=${rain}&chill=${chill}&heat=${heat}`;
      const xhttp = new XMLHttpRequest();

      e.preventDefault();
      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              messageStatusRegister.html("Houve um erro, tente mais tarde");
            }
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    });
  },
  formatWeatherPreference: (preference) => {
    if (preference == 1) return 33
    else if (preference == 2) return 66
    else return 99
  },
  listenerStateChange: () => {
    const uf = $('#stateSelect').data('state')
    MyClimatempo.getCitiesByUf(uf)

    // wait for change in state select
    $(document).on('change', '#stateSelect', function() {
      const value = $(this).val()

      MyClimatempo.getCitiesByUf(value)
    })
  },
  getCitiesByUf: (uf) => {
    const url = `/json/busca-cidades-uf?uf=${uf}`
    const xhttp = new XMLHttpRequest()

    try {
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const response = JSON.parse(this.response)
          const data = response.data
          let options = ''
          let city = $('#citySelect').data('city')

          $("#citySelect").empty();

          for (let i = 0; i < data.length; i++) {
            let selected = ''

            if (data[i].idcity === city) selected = 'selected'

            options += `<option value=${data[i].idcity} ${selected}>
                          ${data[i].city}
                        </option>`
          }

          $('#citySelect').append(options)
        }
      };
      xhttp.timeout = 30000;
      xhttp.open("GET", url, true)
      xhttp.send()
    } catch (e) {
      messageStatusRegister.html("Houve um erro, tente mais tarde")
    }
  },
  progressColorPreference: () => {
    $('.custom-range-slider').change(function() {
      let val = ($(this).val() - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min'));

      $(this).css(
        'background-image',
        '-webkit-gradient(linear, left top, right top,'
        + 'color-stop(' + val + ', #0080CD),'
        + 'color-stop(' + val + ', #B7B6B6)'
        + ')'
      )
    })
  },
  switchRainNotificationByCity: () => {
    $(document).on('click', '[id^=switchRainNotification]', function(e) {
      const localeId = $(this).data('localeid')
      const city = $(this).data('city')
      const uf = $(this).data('uf')
      const region = $(this).data('region')
      const channel = $(this).data('channel')

      const enabled = $(this).is(':checked') ? 1 : 0

      const url = `/json/myclimatempo/user/rainNotification?localeId=${localeId}&city=${city}&uf=${uf}&region=${region}&enabled=${enabled}`
      const xhttp = new XMLHttpRequest()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            Main.pushSubscription(channel, enabled);
          }
        }
        xhttp.open("GET", url, true)
        xhttp.send()
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    })
  },
  switchButtonNotification: () => {
    $('#switchNotification').click(function() {
      let text, value

      if ($(this).is(':checked')) {
        text = 'Habilitado'
        value = 1
        $('#notificationsDisabledAlert').addClass('_none')
      } else {
        text = 'Desabilitado'
        value = 0
        $('#notificationsDisabledAlert').removeClass('_none')
      }

      $('#statusCheckbox').text(text)

      const url = `/json/myclimatempo/user/alertsEnabled?enabled=${value}`
      const xhttp = new XMLHttpRequest()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response)
          }
        }
        xhttp.open("GET", url, true)
        xhttp.send()
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    })
  },
  markAlertAsRead: () => {
    $(document).on('click', '.actMarkAlertAsRead', function(e) {
      const id = $(this).data('id')
      const href = $(this).data('href')
      const url = `/json/myclimatempo/user/readAlert?id=${id}`
      const xhttp = new XMLHttpRequest()

      window.open(href, '_blank')
      $(this).removeClass('_none').find('.unread').remove()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response)

            MyClimatempo.countAlerts()
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    })
  },
  countAlerts: () => {
    const url = `/json/myclimatempo/user/countAlerts`
    const xhttp = new XMLHttpRequest()

    try {
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const response = this.response

          if (response > 0) {
            $('#unreadNotifications').removeClass('_none').html(response)
            $('#unreadNotificationsHeader').removeClass('_none').html(response)
          } else {
            $('#unreadNotifications, #unreadNotificationsHeader').addClass('_none')
          }
        }
      };
      xhttp.timeout = 30000;
      xhttp.open("GET", url, true);
      xhttp.send();
    } catch (e) {
      messageStatusRegister.html("Houve um erro, tente mais tarde");
    }
  },
  sendUserSuggestion: () => {
    $('#formSendSuggestion').on('submit', (e) => {
      const formInputs = $('#formSendSuggestion').find(':input:not(:submit)');
      const suggestion = formInputs[0].value;

      const url = `/json/myclimatempo/user/sendSuggestion?suggestion=${suggestion}`;
      const xhttp = new XMLHttpRequest();

      e.preventDefault();
      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response);
            // Something goes wrong on server side
            if (response.message) {
              messageStatusRegister.html("Houve um erro, tente mais tarde");
            }

            formInputs[0].value = ''
          }
        };
        xhttp.timeout = 30000;
        xhttp.open("GET", url, true);
        xhttp.send();
      } catch (e) {
        messageStatusRegister.html("Houve um erro, tente mais tarde");
      }
    });
  },
  setWelcomeIntro: () => {
    const url = `/json/myclimatempo/user/setWelcome`;
    const xhttp = new XMLHttpRequest();

    try {
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          const response = JSON.parse(this.response);
        }
      };
      xhttp.timeout = 30000;
      xhttp.open("GET", url, true);
      xhttp.send();
    } catch (e) {
      messageStatusRegister.html("Houve um erro, tente mais tarde");
    }
  },
  /**
   * Cria mascara de telefone para input do meu climatempo
   * Meu Climatempo
   */
  mask: () => {
    const date = new Date()
    const phone = document.getElementById('phone')
    const datepicker = document.getElementById('birthday')

    if (phone) {
      const maskPhone = {
        mask: '(00) 00000-0000'
      };

      IMask(phone, maskPhone)
    }

    if (datepicker) {
      TinyDatePicker(datepicker, {
        lang: {
          days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          months: [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ],
          today: 'Hoje',
          clear: 'Limpar',
          close: 'Fechar'
        },
        format(dt) {
          return dt.toLocaleDateString('pt-BR')
        },
        max: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      });
    }
  },
  editEmailForecast: () => {
    $(document).on('click', '#switchEmailForecast', function () {
      if ($(this).prop('checked')) {
        $('#modalEmailForecast #msg').html('Obrigado por ativar a previsão por e-mail, a partir de agora você passará a receber nosso informativo.')
        $('[data-id=day]').prop('checked', true).prop('disabled', false)
        $('[data-id=label-day').removeClass('-disabled')
      } else {
        $('#modalEmailForecast #msg').html('Você desativou a previsão por e-mail, a partir de agora não receberá nosso informativo.')
        $('[data-id=day]').prop('checked', false).prop('disabled', true)
        $('[data-id=label-day').addClass('-disabled')
      }
    })

    $('#formEditEmailForecast').on('submit', (e) => {
      const formInputs = $('#formEditEmailForecast').find(':input:not(:submit)')

      const mon = formInputs[0].checked ? 1 : 0
      const tue = formInputs[1].checked ? 1 : 0
      const wed = formInputs[2].checked ? 1 : 0
      const thu = formInputs[3].checked ? 1 : 0
      const fri = formInputs[4].checked ? 1 : 0
      const sat = formInputs[5].checked ? 1 : 0
      const sun = formInputs[6].checked ? 1 : 0

      const url = `/json/myclimatempo/user/editEmailForecastPreferences?mon=${mon}&tue=${tue}&wed=${wed}&thu=${thu}&fri=${fri}&sat=${sat}&sun=${sun}`
      const xhttp = new XMLHttpRequest()

      e.preventDefault()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response)

            if (response.message) {}
          }
        }

        xhttp.timeout = 30000
        xhttp.open("GET", url, true)
        xhttp.send()
      } catch (e) {}
    });
  },
  openTabByHash: () => {
    const hash = location.hash.split('#')[1]

    if (hash) {
      $(`#${hash} .accordion-content`).removeClass('_none')
      $(`#${hash} .simple-arrow`).addClass('-down')
    }
  },
  controlTabByHash: () => {
    $(document).on('click', '.actControlTabByHash', function() {
      const href= $(this).data('href')
      window.location = href

      MyClimatempo.openTabByHash()
    })
  },
  editAlternativeEmail: () => {
    $('#formEditAlternativeEmail').on('submit', (e) => {
      const formInputs = $('#formEditAlternativeEmail').find(':input:not(:submit)')
      const alternativeEmail = formInputs[0].value

      const url = `/json/myclimatempo/user/editAlternativeEmail?alternativeEmail=${alternativeEmail}`
      const xhttp = new XMLHttpRequest()

      e.preventDefault()

      try {
        xhttp.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            const response = JSON.parse(this.response)

            if (response.data && response.status_code) {
              $('body').addClass('_overflow-hidden')
              $('#modalEmailForecast, .wrapper-modal').toggleClass('-active')
              $('#modalEmailForecast #msg').html('A partir de agora você passará a receber nosso informativo através do seu e-mail alternativo.')
            }
          }
        }

        xhttp.open("GET", url, true)
        xhttp.send()
      } catch (e) {}
    })
  },
  checkValidEmail:() =>{
    let element;
    let elementErrMsg;
    let isLogin, isRegister, isRecover;
    if($('#emailInput').length){
      element = $('#emailInput');
      elementErrMsg = $("#messageStatusLogin");
      isLogin = true;
    }else if($('#input-register-email').length) {
      element = $('#input-register-email');
      elementErrMsg = $("#messageStatusRegister");
      isRegister = true;
    } else if($('#emailInputRecover').length){
      element = $('#emailInputRecover');
      elementErrMsg = $("#messageStatusRecover");
      isRecover = true;
    }

    if(element){
      element.blur((e) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email = element.val();
        let check = true;

        if(!isRecover){
          check = re.test(email);
        }

        if(check){
          const url = `/json/myclimatempo/user/check?email=${email}`;
          const xhttp = new XMLHttpRequest();
          try {
            xhttp.onreadystatechange = function() {
              if(this.readyState == 4 && this.status == 200){
                const response = JSON.parse(this.response);
                let errMsg = ""; 
                let error = false;
  
                if(response.data.emailExists && isRegister){
                  error = true;
                  errMsg = 'E-mail digitado já está cadastrado. <a href="/login/email" style="text-decoration:underline; color: #E2251A;">Faça seu login</a>';
                } else if(!response.data.emailExists && !isRegister) {
                  error = true;
                  errMsg = 'E-mail digitado não está cadastrado.';
                }
  
                if(error){
                  element.addClass("_boder-red-2").focus();
                  $('#errEmail_icon').removeClass("_none");
                  elementErrMsg.removeClass("_none").html(`<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>${errMsg}`);
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event' : 'error_hit',
                    'name' : 'login_general-input-error-blur'
                  })
                } else{
                  element.removeClass("_boder-red-2");
                  $('#errEmail_icon').addClass("_none");
                  elementErrMsg.addClass("_none");
                  if(isRecover){
                    $("#btnSendPasswordRecover").prop("disabled", false);
                  }
                }
              }
            };
            xhttp.timeout = 30000;
            xhttp.open("GET", url, true);
            xhttp.send();
          } catch (e) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event' : 'error_hit',
              'name' : 'login_general-server-error-blur'
            })
            $("#messageStatusMyClimatempo").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>Tente mais tarde. <br> O Meu Climatempo está indisponível no momento.')
          }
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'error_hit',
            'name' : 'login_general-input-error-blur'
          })
          element.addClass("_boder-red-2").focus();
          $('#errEmail_icon').removeClass("_none");
          elementErrMsg.removeClass("_none").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>Por favor, digite um e-mail válido!');
        }
      })
    }
  },
  enableButton: () => {
    $('#passwordInput').keyup(() => {
      const password = $('#passwordInput').val();
      const email = $('#emailInput').val();
      
      if(email && email.trim() != "" && password && password.trim() != ""){
        $('#btnSendUserLogin').removeAttr("disabled");
      } else {
        $('#btnSendUserLogin').attr("disabled", "disabled");
      }
    })
  },
  checkPasswordConfirmation: () => {

    $("#repeatPasswordInput").blur((e) => {
      const password = $("#passwordInput").val();
      const repeatPassword = $("#repeatPasswordInput").val();

      const email = $("#input-register-email").val();
      const name = $("#inputName").val();

      if(password && repeatPassword && repeatPassword === password){
        $("#passwordInput").removeClass("_border-red-2").addClass("_boder-green-2");
        $("#repeatPasswordInput").removeClass("_border-red-2").addClass("_boder-green-2");
        $('#messageStatusPassword').addClass("_none");
        // if(email && email.trim() != "" && name && name.trim() != ""){
        //   $('#btnSendUserRegister').prop("disabled",false);
        // }
      } else {
        $('#btnSendUserRegister').prop("disabled", true);
        $("#passwordInput").addClass("_boder-red-2").focus();
        $("#repeatPasswordInput").addClass("_boder-red-2").val(null);
        $('#messageStatusPassword').removeClass("_none").html('<i class="fa fa-exclamation-circle" style="padding-right: 5px;"></i>As senhas não coincidem!');
      }
    })

  }
}

Main.init()
MyClimatempo.controlPasswordVisible()
MyClimatempo.userRegister()
MyClimatempo.controlDeleteAccount()
MyClimatempo.editUserData()
MyClimatempo.userLoginEmail()
MyClimatempo.passwordRecover()
MyClimatempo.activeTab()
MyClimatempo.controlAsideMenu()
MyClimatempo.toggleDeleteIcon()
MyClimatempo.removeUserFavoriteLocale()
MyClimatempo.addUserFavoriteLocale()
MyClimatempo.getWelcomeIntro()
MyClimatempo.weatherPreferenceController()
MyClimatempo.userWeatherPreferences()
MyClimatempo.formatWeatherPreference()
MyClimatempo.listenerStateChange()
MyClimatempo.progressColorPreference()
MyClimatempo.switchButtonNotification()
MyClimatempo.sendUserSuggestion()
MyClimatempo.mask()
MyClimatempo.editPassword()
MyClimatempo.controlModalEditPass()
MyClimatempo.switchRainNotificationByCity()
MyClimatempo.editEmailForecast()
MyClimatempo.openTabByHash()
MyClimatempo.controlTabByHash()
MyClimatempo.editAlternativeEmail()
MyClimatempo.checkValidEmail()
MyClimatempo.enableButton()
MyClimatempo.checkPasswordConfirmation()